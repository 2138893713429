import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';  // Updated import
import { getAfterImage, getSessionID, resetSession } from '../Model';
import { Tween } from 'react-gsap';
import '../../scss/FilterError.scss';

const APP_URL = process.env.REACT_APP_URL;

export default function FilterError({ settings }) {
    const navigate = useNavigate();
    const imgsrc = getAfterImage();
    const qrRef = useRef(null);
    const emailRef = useRef(null);
    const classRef = useRef(null);

    const [state, setState] = useState('qr');
    const [interacted, setInteracted] = useState(false);
    const siteWrap = document.getElementById('App');
    const btnText = "done";

    // check if element contains class on mount
    useEffect(() => {
        handleQR();
    }, []);

    function handleDone() {
        resetSession();
        navigate('/');
    }

    function handleEmail() {
        setState('email');
        setInteracted(true);
    }

    function handleQR() {
        setInteracted(true);
    }

    return (
        <div ref={classRef} className="fullscreen filterError">
            <div className='wave'></div>
            <div>
                <Tween from={{ opacity: 0, y: 50, stagger: .2 }}>
                    <div className='title-wrap'>
                        <h1>Sorry, the <span className='light-purple-text'>AI is busy</span> right now...</h1>
                        <h5>{settings.intro}<br/>Scan the QR code and leave your details. </h5>
                    </div>
                </Tween>
                <div className='outside'>
                    <div className='inside'>
                        <QRCodeCanvas
                            value={`${APP_URL}/recovery/${getSessionID()}`}  // Generate QR code with dynamic URL
                            fgColor="#2E1A47"  // QR code color
                            bgColor="#ffffff00"  // Background color
                            size={256}  // Size of the QR code
                        />
                    </div>
                </div>
                <div className="button light" onClick={handleDone}><p>{settings.action}</p><div className='arrow'></div></div>
            </div>
            
        </div>
    );
}
