import React, { Component, useState, useEffect, useRef } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Tween } from 'react-gsap';
import { getEntry, setSetting, getSetting } from './Model';

export default function Question({settings, foreground, background}) {
    const navigate = useNavigate();
    const [ answer, setAnswer ] = useState( getSetting('question'+settings.qNum) );
    const [ isActive, setActive ] = useState(answer!=undefined);  
    const msettings = require('../config.json');


    function next(){
        console.log('calling next()');
        if (settings.qNum === "1" && isActive) { // Use '===' to check strict equality
            navigate('/takepic'); // Removed extra argument
        }
    }

function handleOptionClick(e) {
    console.log("isActive ", isActive);
    const val = e.currentTarget.getAttribute('id');
    setAnswer(val);
    setSetting('question' + settings.qNum, val);
    console.log ("filter val ", val)
    if (settings.qNum === '1') {
        // Example logic to determine the filter
        const filter = val;
        //console.log('Determined filter:', filter);
        //setSetting('filter'+ filter);
        setSetting('fgpath',process.env.REACT_APP_URL+foreground.path);
        setSetting('bgpath',process.env.REACT_APP_URL+'/images/achievers/background-final.jpg');
        console.log('set',process.env.REACT_APP_URL+'/images/achievers/background-final.jpg');
        console.log("filter: ", filter)
        setSetting('question1', filter);
        setSetting('question2', "abstract");
    }

    setActive(true);
    

}



    return (
        <div className={`fullscreen questions ${settings.page}`}>
                <Tween from={{ y:50, alpha:0, stagger:.2 }}>
                {/* logo here */}
                    <h1>Select your <span className='light-purple-text'>style.</span></h1>
                    <div className='grid-wrap'>                        
                        {settings.excerpt != "" && <p>{settings.excerpt}</p>}
                        <div className='q-grid'>
                            
                            { settings.options.map( (option,index) => (
                                <button onClick={handleOptionClick} key={index} data-index={index} id={option.id} className={`question ${option.id===answer?'selected':''}`}>
                                    { (option.imgURL != "") &&
                                        <div className='thumbnail'><img src={option.imgURL} /></div>
                                    }
                                    { (option.label != "") &&
                                        <span className='question-text'>{option.label}</span>
                                    }
                                </button>
                                )
                            )}
                        </div>

                    </div>

                </Tween>
            <div className='footer-btns'>
                {/* <button id="backLink" onClick={back} className='control-cta light back-btn'>{settings.ctaLast}</button> */}
                <div id="nextLink" onClick={next} className={`button control-cta primary ${!isActive ? 'disabled' : ''}`}><p>{settings.ctaNext}</p><div className='primary-arrow'></div></div>
            </div>
            
        </div> 
    );
}
