import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createEntry } from '../Model';
import { Tween } from 'react-gsap';
import '../../scss/Welcome.scss';

export default function Welcome({wsettings,dsettings}){
    const navigate = useNavigate();
    const imgRef = useRef(null);
    const welcRef = useRef(null);
    const discRef = useRef(null);
    const discFullRef = useRef(null);
    const butRef = useRef(null);
    const butFullRef = useRef(null);
    const modalRef = useRef(null);
    const settings = require('../../config.json');

    let disclaimerAction = dsettings.mode; // 0 no disclaimer.
    // 1 = show excerpt disclaimer, continue when clicking yes
    // 2 = show excerpt discliamer, show modal on clicking yes
    // let fullDisclaimerText = '';
    // let fullDisclaimerCTA = '';

    const [ showDisclaimer, setShowDisclaimer ] = useState(false);

    function handleDisclaimerClick(){
        setShowDisclaimer(true);
    }
    async function handleAcceptClick(){
        try {
            await createEntry();
            // You can handle the result here if needed
            navigate('/question1');
        } catch (error) {
            if (error.response && error.response.status === 502) {
                //502 - server not running
                // Display a message on the screen
                window.alert('502 Bad Gateway. Please contact tech support.');
            } else {
                // Handle other types of errors
                //404 - entry not found
                window.alert('An error occurred: '+ error.response.status+'. Please try again later.');
            }
            navigate('/');
        }
        
    }
    function handleCloseClick(){
        setShowDisclaimer(false);
    }

    function handleBackClick(){
        navigate('/');
    }


    return (

        <div className="fullscreen welcome">
            <Tween from={{ opacity:0, y:50, stagger:.2 }}>
            <div>
                <h1>Just <span className='orange-text'>one more</span> thing.</h1>
            </div>
            <div className='disclaimer'>
                <h2>Disclaimer</h2>
                <p ref={discRef} dangerouslySetInnerHTML={{__html:dsettings.excerpt}}></p>
                </div>
                <div className='footer-btns'>
                    {/* <button className='dark back-btn control-cta' onClick={handleBackClick}>Back</button> */}
                    <div className='button light control-cta' onClick={handleAcceptClick} ref={butRef}>
                        <p>{dsettings.cta}</p>
                        <div className='arrow'></div>
                    </div>
                </div>
                </Tween>
                {showDisclaimer && <div className="modal" ref={modalRef}>
                    <h2>Disclaimer</h2>
                    <p ref={discFullRef} dangerouslySetInnerHTML={{__html:dsettings.full}}></p>
                    <button onClick={handleCloseClick} ref={butFullRef}>{dsettings.close}</button>
                </div>}
        </div>
    );
}