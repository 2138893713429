import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../scss/Camera.scss';
import { setImageSet, processImage } from '../Model';
import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-converter";
import "@tensorflow/tfjs-backend-webgl";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import Countdown from '../Countdown';
import { getEntry, setSetting, getSetting } from '../Model';

// const BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;
const AWS_URL = process.env.REACT_APP_AWS_URL;
const AWS_ID = process.env.REACT_APP_AWS_ID;
const AWS_SECRET = process.env.REACT_APP_AWS_SECRET;

let bpn;


let preventMulti = true;

export default function Camera({settings}){
    const navigate = useNavigate();

    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [mediaStream, setMediaStream] = useState(null);

    const bgRef = useRef(null);
    const fgRef = useRef(null);
    const previewRef = useRef(null);
    const imgSrc = useRef(null);

    // const [ options, setOptions ] = useState([]);
    const [ counting, setCounting ] = useState(-1);
    const [ selectedOption, setSelectedOption ] = useState(0);
    const [ isVertical, setIsVertical ] = useState(false);
    const optionType = settings.options.length>0 ? settings.options[0].type : '';

    
    useEffect(() => {
        // Start the camera when the component mounts
        startCamera();
    
        // Clean up the camera stream when the component unmounts
        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    const startCamera = async () => {
        try {
            const constraints = {
                video: {
                    facingMode: "user", // Ensure it's using the front camera
                    width: { ideal: 768 },
                    height: { ideal: 1024 },
                    // iOS specific constraint to avoid wide-angle
                    advanced: [
                        { aspectRatio: 4 / 3 }, // Target the standard aspect ratio
                    ]
                }
            };
    
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            videoRef.current.srcObject = stream;
            setMediaStream(stream);
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };
    

    function handleTakePic(){
        preventMulti = true;
        setCounting(1);

        
        callTimer()
       //flash here


    }

    function callTimer() {
        const timer = document.getElementById("counter");
        document.getElementById("buttons1").style.display = 'none';
        
        let timeLeft = 3;
        timer.innerHTML = timeLeft;
        timer.style.display = 'block';
        
        const timerId = setInterval(() => {
            timeLeft--;
            timer.innerHTML = timeLeft;
    
            if (timeLeft < 0) {
                clearInterval(timerId); // Clear the interval to stop the timer
                imgSrc.ref = stopCamera();
                document.getElementById("how-look").innerHTML = "How does this <span class='light-purple-text'>look to you?</span>";

                document.getElementById("notice-camera").style.color = 'transparent';
                
                document.getElementById("buttons2").style.display = 'flex';
                timer.style.display = 'none';
                console.log("Time's up!");
            }
        }, 1000);
    }
    


    async function retakePhoto() {
        document.getElementById("how-look").innerHTML = "Let’s take <span class='light-purple-text'>a selfie.</span>";
        document.getElementById("notice-camera").style.color = 'black';
        document.getElementById("buttons1").style.display = 'flex';
        document.getElementById("buttons2").style.display = 'none';

        preventMulti = true;
        startCamera();
    
    };


    function stopCamera() {
        videoRef.current.pause();

        console.log("preventMulti ", preventMulti);
        if (!preventMulti) return; // second time getting called? only seems to happen when background is on.
        preventMulti = false;
        const beforeCanv = document.createElement('canvas');
        beforeCanv.width = videoRef.current.videoWidth;
        beforeCanv.height = videoRef.current.videoHeight;
        const beforeCtx = beforeCanv.getContext('2d');
        beforeCtx.scale(-1, 1);
        beforeCtx.drawImage(videoRef.current,-beforeCanv.width,0,beforeCanv.width,beforeCanv.height);
        canvasRef.current = beforeCanv;
        const beforeImgSrc = beforeCanv.toDataURL('image/jpg');
        // if (mediaStream) {
        //     mediaStream.getTracks().forEach(track => track.stop());
        //     setMediaStream(null);
        // }
        return beforeImgSrc;
    }
    

    function looksGreat(){
        displayProcessing();
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => track.stop());
            setMediaStream(null);
        }
       handleCountdownComplete();
    };

    function displayProcessing(){
        document.getElementById("headshot").style.display = 'none';
        document.getElementById("buttons2").style.display = 'none';
        document.getElementById("processing").style.display = 'flex';
        document.getElementById("camera-canvas").style.display = 'none';
    }

    function turnOffProcessing(){
        document.getElementById("headshot").style.display = 'block';
        document.getElementById("buttons1").style.display = 'block';
        document.getElementById("processing").style.display = 'none';
        document.getElementById("camera-canvas").style.display = 'block';
    }

    function handleCountdownComplete(){
        const beforeImgSrc = imgSrc.ref;
        
        //console.log("imgSrc.ref ", imgSrc.ref);
        if (settings.filterProcess==="backend") {
            // send the before image to backend for processing.
            const fetchData = async () => {
                const data = await processImage(beforeImgSrc);
                console.log(data.status)
                if (data.status===500){
                    // redo photo.
                    turnOffProcessing();
                    retakePhoto();
                    document.getElementById("how-look").innerHTML = "Ensure your face is facing forward <span class='light-purple-text'>when taking the photo.</span>";
                    return
                    
                } else if (data.status===501) {
                    //window.alert('There was a 501 error processing your request, please try again!');
                    navigate('/error');
                } else if (data.status===502) {
                    //recovery
                    window.alert('There was a 502 error processing your request, please try again!');
                    navigate('/'); //contact tech support
                } 
                else {
                    
                    navigate('/review');
                }
            }

            //call the function
            fetchData()
            // make sure to catch any error
            .catch(error=>{
                console.log(error);
                window.alert('There was an error processing your request, please try again!');
                navigate('/');
            });

            return;
        }

        // freeze image.
        const img = new Image();
        img.onload = async function(){
            const tempCtx = previewRef.current.getContext("2d");

            tempCtx.drawImage(bgRef.current,0,0,1024,1366);
            // current canvas is 960x720.
            const tw = 1366 * 960/720;
            const tx = (1024 - tw)/2;
            tempCtx.drawImage(img,tx,0,tw,1366);
        //     canvasRef.current.getContext('2d').drawImage(img,0,0,canvasRef.current.width,canvasRef.current.height);
            tempCtx.drawImage(fgRef.current,0,0,1024,1366);

            const finalImgSrc = previewRef.current.toDataURL('image/jpg');
 
            let key = new Date();
            key = key.valueOf();

            if (settings.skipUpload){
                // await setImageSet(beforeImgSrc,finalImgSrc);
            } else {
                const before = await uploadImage(beforeImgSrc,key,0);
                const after = await uploadImage(finalImgSrc,key,1);
                await setImageSet(before,after);
            }
            
            navigate('/review');
        }
        img.src = imgSrc.ref;


    }


    async function uploadImage(imgdatauri,key,index){
        const blob = dataURItoBlob(imgdatauri);
        const folder = index===0 ? 'before' : 'after';
        
        const client = new S3Client({
            bucketEndpoint:AWS_URL,
            region:REGION,
            credentials:{
                secretAccessKey:AWS_SECRET,
                accessKeyId:AWS_ID
            }
        });
        const command = new PutObjectCommand({
            Bucket:AWS_URL,
            ACL:'public-read',
            Key: folder+'/'+key+'.jpg',
            Body:blob
        });
        await client.send(command);
        return 'https://'+AWS_URL+'/'+folder+'/'+key+'.jpg';
    }

    function handleOptionClick(e){
        console.log('option',e.target.getAttribute('data-value'),e.target.getAttribute('data-index'));
        setOption(e.target.getAttribute('data-value'));
        setSelectedOption(Number(e.target.getAttribute('data-index')));
        
    }
    function setOption(val){
        // const webcam = capture.elt;
        //     // const canvas = canvasRef.current;
        //     // const context = canvas.getContext("2d");
        //     initMasking(webcam);
        // return; 

        if (optionType==='foregroundImage,backgroundImage') {
            const paths = val.split(',');
            fgRef.current.src = paths[0];
            bgRef.current.src = paths[1];
        } else if (optionType==='backgroundImage'){
            bgRef.current.src = val;
        }
    }
    

    return (
        <div className={`fullscreen camera ${isVertical?'vert':''}`}>
            <div id= "headshot">
                <h1 id="how-look">Let’s take <span className='light-purple-text'>a selfie.</span></h1>
                <p id="notice-camera">Make sure your photo is clear and centered for the best results.</p>
            </div>
                <img ref={bgRef} className="canvas-background" alt="background" />
                <div id='camera-canvas' className='camera-container'>
                    <video ref={videoRef} autoPlay playsInline muted style={{ transform: 'scaleX(-1)'}}/>
                    <div id='counter'>1</div>
                </div>
                {/* <Sketch preload={preload} setup={setup} draw={draw} /> */}
                <img ref={fgRef} className="canvas-foreground" alt="foreground" />
                

            <div id='buttons1' className="button take-pic" onClick={handleTakePic}><p>{settings.cta}</p><div className='camera-icon'></div></div>
            <div id='buttons2' className='footer-btns' style={{display: "none"}}>
                    <div className='button back-btn' onClick={retakePhoto}><p>Retake</p></div>
                    <div className="button primary" onClick={looksGreat}><p>Continue</p><div className='primary-arrow'></div></div>
            </div>
            <div className="options-wrapper">
                    {settings.options.map((option,index)=>
                        <button data-value={option.value} data-type={option.type} onClick={handleOptionClick} key={index} data-index={index} className={selectedOption===index?'selected':''}>{option.label}</button>
                    )}
            </div>
            <canvas ref={previewRef} className="previewCanvas" width="1400" height="1600" style={{display: "none"}}/>
            <div id='processing' className='outside' style={{display: "none" }}>
                <div id='debugging' className='inside'>
                    <h2>Your photo is <span className='light-purple-text'>being processed...</span></h2>
                    <div id='loading-bar-container'>
                        <div id='loading-bar'alt="Progress Bar"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}