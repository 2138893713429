import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getEntry, setProfile } from '../Model';
// import { Tween } from 'react-gsap';
import '../../scss/Signup.scss';

export default function Signup({settings}){
    const { id } = useParams();
    const [ errors, setErrors ] = useState({});
    const [ submitted, setSubmitted ] = useState(false);
    const [ interacted, setInteracted ] = useState(false);
    const [ userImage, setUserImage ] = useState(null);

    const fields = [ 
        {label:"First name",type:"text",id:"firstname",required:true},
        {label:"Last name",type:"text",id:"lastname",required:true},
        {label:"Company",type:"text",id:"company",required:true},
        {label:"Email Address",type:"email",id:"email",required:true},
        // {label:"Wallet address (optional)",type:"text",id:"walletid"},
        {label:"I consent",type:"checkbox",id:"waitlist"},
        // {label:"Get an NFT of this photo",type:"checkbox",id:"nftoptin"}
    ];

    function handleInteracted(){
        setInteracted(true);
        window.scrollTo(0, 0);
    }


    async function handleSubmit(){
        window.scrollTo(0, 0);
        const err = {};
        const data = {};
        fields.forEach( (field,index) => {
            const input = document.getElementById(field.id);
            if (field.required && input.value===''){
                err[field.id] = true;
            }
           data[field.id] = (field.type==='checkbox') ? input.checked : input.value;
        });
        // setErrors(err);
        // if(JSON.stringify(err)==='{}') { // check if err is empty.
        //     console.log('submit!',data);
        //     const result = await setProfile(data);
        //     console.log(result);
        //     if (result.status==200){
        //         setSubmitted(true);
        //     } else {
        //         setErrors({email:true});
        //     }
            
        // }
        setErrors(err);
        if (Object.keys(err).length === 0) { // No errors
            console.log('submit!', data);
            const result = await setProfile(data);
            console.log(result);
            if (result.status === 200) {
                setSubmitted(true);
            } else {
                setErrors({email:true});
                // Handle submission error
            }
        }
    }

    const showError = Object.keys(errors).length > 0;

    useEffect( () => {
        console.log('load user',id);
        window.scrollTo(0, 0);
        // declare the data fetching function
        const fetchData = async () => {
            const data = await getEntry(id);
            console.log(data);
            if (data){
                setUserImage(data.afterpic);
                if (data.email) {
                    setInteracted(true);
                    setSubmitted(true);
                }
            } else {
                setErrors({sessionid:true});
                setInteracted(true);
            }
        }

        // call the function
        fetchData()
        // make sure to catch any error
        .catch(console.error);
    },[id]);

    return (
        <div className="fullscreen signup mobile">


            {/* error state */}
            { errors.sessionid
            ? <>
                    <h1>User not found</h1>
                    <h2>Please check your link and try again.</h2>
              </>
            : <>
            
            {submitted 
                ? (
                    <p className='thankyou-message'>Thank you for submitting , you will receive your photo soon in <span className='light-purple-text'>your inbox!</span></p>
                ) : (
                    <>
                        <div className='signup-heading'>
                            <h1>Submit your detail to <span className='light-purple-text'>get your photo.</span></h1>
                            <p>Your photo will be sent to your email,<br/>keep an eye on your inbox!</p>
                        </div>
                    </>
                )
            }

            
            {userImage && <>
                { <img className="userImage" src={userImage} alt="this is you!" />}
            </>}

            {submitted ? 
            <>
                {/* <a className="button" href={userImage} download>Download photo</a> */}
                {/* <p style={{width: '80%'}} dangerouslySetInnerHTML={{__html:settings.emailsuccess.body}}></p> */}
            </>
            :
            <>
                {/* <p>Thank you for participating! Please provide your email below to receive a copy of your photo.</p>
                <p>Please provide your wallet address to receive a special NFT of your photo and get early access to Astound.</p> */}
            <div className="field-wrap">
            {showError && (
                <div className="form-error">
                    *Please check below to ensure you have entered your information correctly.
                </div>
            )}
            
            <form onSubmit={e => {
                e.preventDefault();
                handleSubmit();
            }}>
            
                {fields.map(field => (
                    <div key={field.id} className={field.id} >
                        {/* <label htmlFor={field.id}>{field.label}</label> */}
                        <input
                            type={field.type}
                            id={field.id}
                            name={field.id}
                            onFocus={handleInteracted}
                            placeholder={field.label}
                        />
                        {/* Conditionally render extra <p> tag for the checkbox */}
                        {field.type === 'checkbox' && (
                        <div className="checkbox-container">
                            <label className="custom-checkbox">
                                <input type="checkbox" id={field.id} />
                                <span className="custom-checkmark"></span>
                                <p className="checkbox-info">I consent to receiving promotional marketing emails from Achievers and understand that I can opt out at any time.</p>
                            </label>
                        </div>
                        )}
                    </div>
                ))}
                
            
                {/* <button type="submit">Submit</button> */}
            </form>
            </div>
            <div className="button primary" onClick={handleSubmit} type="submit" value={ settings.email.formaction }><p>Submit</p><div className='primary-arrow'></div></div>
                {/* <input type="submit" value={ settings.email.formaction } onClick={handleSubmit}></input> */}
            </>
            }

            </>}
        </div>
    );
}