import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getEntry, setProfile } from '../Model';
import { Tween } from 'react-gsap';
import '../../scss/Signup.scss';

export default function Recovery({settings}){
    const { id } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ errors, setErrors ] = useState({});
    const [ submitted, setSubmitted ] = useState(false);
    const [ interacted, setInteracted ] = useState(false);
    const [ userImage, setUserImage ] = useState(null);

    const fields = [ {label:"First name*:",type:"text",id:"firstname",required:true},
        {label:"Last name*:",type:"text",id:"lastname",required:true},
        {label:"Email*:",type:"email",id:"email",required:true},
        {label:"I consent",type:"checkbox",id:"consent",required:true}
    ];

    function handleInteracted(){
        setInteracted(true);
    }

    async function handleSubmit(evt){
        evt.preventDefault();
        const err = {};
        const data = {};
        fields.forEach( (field,index) => {
            const input = document.getElementById(field.id);
            if (field.required && input.value===''){ // todo. proper validation
                err[field.id] = true;
            } else if (field.type==='checkbox' && field.required && !input.checked) {
                err[field.id] = true;
            }
            data[field.id] = (field.type==='checkbox') ? input.checked : input.value;
        });
        setErrors(err);
        if(JSON.stringify(err)==='{}') { // check if err is empty.
            console.log('submit!',data);
            const result = await setProfile(data);
            console.log(result);
            if (result.status==200){
                setSubmitted(true);
            } else {
                setErrors({email:true});
            }
            
        }
    }

    useEffect( () => {
        console.log('load user',id);
        setInteracted(true);
        // declare the data fetching function
        const fetchData = async () => {
            const data = await getEntry(id);
            console.log(data);
            setLoading(false);
            if (data) {
                setUserImage(data.afterpic);
                if (data.email) {
                    setInteracted(true);
                    setSubmitted(true);
                }
            } else {
                setErrors({sessionid:true})
                setInteracted(true);
            }
        }

        // call the function
        fetchData()
        // make sure to catch any error
        .catch(console.error);
    },[id]);

    return (
        <div className="fullscreen recovery signup mobile">

            {loading
            ? <>
                <span className="loaderIcon"></span>
            </>
            : <>

            {/* error state */}
            {errors.sessionid
            ? <Tween from={{ opacity:0, y:50, stagger:.2 }}>
                <div className='title-wrap'><h1>
                    <Tween from={{ x:-500, stagger: 0.2 }}>
                        <span>User not</span>
                        <span>found</span>
                    </Tween>
                </h1></div>
                <p>Please check your link and try again.</p>
            </Tween>
            : <>

            {/* not interacted yet. */}
            {!interacted && 
            <Tween from={{ opacity:0, y:50, stagger:.2 }}>
                <div className='title-wrap'><h1>
                    <Tween from={{ x:-500, stagger: 0.2 }}>
                        <span>{ settings.title.split('\n')[0] }</span>
                        <span>{ settings.title.split('\n')[1] }</span>
                    </Tween>
                </h1></div>
                <p>{ settings.intro }</p>
                <button onClick={handleInteracted}>{ settings.introCta }</button>
                <>
                {userImage && <img src={userImage} alt="this is you!" />}
                </>
            </Tween>}

            {submitted && <Tween from={{ opacity:0, y:50, stagger:.2 }}>
                <div className='title-wrap'><h1>  
                    <Tween from={{ x:-500, stagger: 0.2 }}>
                        <span>{ settings.successTitle.split('\n')[0] }</span>
                        <span>{ settings.successTitle.split('\n')[1] }</span>
                    </Tween>
                </h1></div>
                <p>{ settings.successSubtitle }</p>
                {/* <a className="button" href={userImage} download>Download photo</a> */}
                <>
                {userImage && <img src={userImage} alt="this is you!" />}
                </>
                <p>{ settings.success }</p>
            </Tween>}

            { (interacted && !submitted) 
            && <Tween from={{ opacity:0, y:50, stagger:.2 }}>
                <div className='title-wrap'><h1>  
                    <Tween from={{ x:-500, stagger: 0.2 }}>
                        <span>{ settings.title.split('\n')[0] }</span>
                        <span>{ settings.title.split('\n')[1] }</span>
                    </Tween>
                </h1></div>
                <p>{ settings.intro }</p>
                <form className="field-wrap">
                {fields.map( (field,index) => (
                    <div className={'wrap-'+field.type+(errors[field.id]?' error':'')} key={index}>
                        <label htmlFor={field.id}>{field.label}</label>
                        <input type={field.type} id={field.id}></input>
                    </div>
                ) )}
               
                <input type="submit" value="Submit" onClick={handleSubmit}></input>
            </form></Tween>}

            </>
            }  {/* end error if */}

            </>
            } {/* end loading if */}
            
        </div>
    );
}