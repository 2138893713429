import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import QRCode from 'qrcode';
import { QRCodeCanvas } from 'qrcode.react';
import { getAfterImage, getSessionID, resetSession } from '../Model';
import { Tween } from 'react-gsap';
import '../../scss/Preview.scss';

const APP_URL = process.env.REACT_APP_URL;

export default function Preview({settings}){
    const navigate = useNavigate();
    const imgsrc = getAfterImage();
    const qrRef = useRef(null);
    const emailRef = useRef(null);

    const [ state, setState ] = useState('preview');
    const [ interacted, setInteracted ] = useState(false);

    function handleRetake(){
        navigate('/question1');
    }
    function handleDone(){
        resetSession();
        navigate('/');
    }
    function handleEmail(){
        setState('email');
        setInteracted(true);
    }
    function handleQR(){
        setState('qr');
        setInteracted(true);
        
        // const page = APP_URL+'/signup/'; // get actual url
        // const id = getSessionID();
        // // console.log(page);
        // QRCodeCanvas.toDataURL(page+id)
        // .then(url => {
        //     // console.log(url)
        //     qrRef.current.src = url;
        // })
        // .catch(err => {
        //     // console.error(err)
        // })
    }
    function sendEmail(){
        // todo. send email logic.
        setState('preview');
    }
    function reset(){
        setState('preview');
    }

    return (
        <div className="fullscreen preview">
            <Tween from={{ opacity:0, y:50, stagger:.2 }}>
                <div className="nav">
                    <span onClick={handleDone}>Done</span>
                    {!interacted && <span onClick={handleRetake}>Retake</span>}
                </div>
                <div>
                <h1 style={{marginTop:0}}>Here is your <span className='light-purple-text'>AI photo.</span></h1>
            </div>
                <div className="preview-wrapper">
                    <img src={imgsrc} alt="this is you!" />
                </div>
                <div className="options footer-btns">
                    {!interacted && <div className="button back-btn" onClick={handleRetake}><p>Retake</p></div>}
                    {settings.email.enabled && 
                        <div className="button primary" onClick={handleEmail}><p>{settings.email.cta}</p><div className='primary-arrow'></div></div>}
                    {settings.qrcode.enabled && 
                        <div className="button primary" onClick={handleQR}><p>{settings.qrcode.cta}</p><div className='primary-arrow'></div></div>}
                </div>
            </Tween>
            {(state==='email' || state==='qr') && 
                <div className="modal">
                    {state==='email' && <div>
                            <p>{settings.email.label}</p>
                            <input type="text" id="email" ref={emailRef} />
                            <button onClick={sendEmail}>{settings.email.action}</button>
                        </div>}
                    {state==='qr' && <div>
                        <div className='wave'></div>
                        <img className ="secondary-logo App-header" src="./images/achievers/logo-purple.svg"/>
                            <h1>Scan QR code to get<br/>your <span className='light-purple-text'>photo emailed.</span></h1>
                            <div className='outside'>
                                <div className='inside'>
                                {/* <img ref={qrRef} alt="QR Code" /> */}
                                <QRCodeCanvas
                                    value={`${APP_URL}/signup/${getSessionID()}`}  // Generate QR code with dynamic URL
                                    fgColor="#2E1A47"  // QR code color
                                    bgColor="#ffffff00"  // Background color
                                    size={256}  // Size of the QR code
                                />
                                </div>
                            </div>
                            <div className='button light' onClick={handleDone}><p>{settings.qrcode.action}</p><div className='arrow'></div></div>
                        </div>}
                </div>}
        </div>
    );
}