const axios = require('axios');

let api = '';
let apiPath = '';
let apiPostfix = '';

let localsettings = '';
let localimage;

let session;

export function init(_apiPath,_apiPostfix){
    api = axios.create({ baseURL: _apiPath });
    apiPath = _apiPath;
    apiPostfix = _apiPostfix;
}

export async function getSettings(siteid) {
    if(localsettings!=='') return localsettings;

    const { data: {data:settings} } = await api.get('settings'+apiPostfix+'?siteid='+siteid);
    localsettings = settings;
    return settings;
}

export async function getEntry(sessionid) {
    const { data: {entry:result} } = await api.get('entry'+apiPostfix+'?sessionid='+sessionid);
    if (result) {
        session = result;
        session.sessionid = sessionid;
    }
    return session;
}
export async function createEntry(){
    const { data:result } = await api.post('entry'+apiPostfix);
    console.log("entry ", result);
    session = result;
    return result;
}

export async function processImage(imgdatauri){
    const blob = dataURItoBlob(imgdatauri);
    console.log("session ", session);
    console.log("imgdatauri ", blob);
    const { data:result } = await api.post('process'+apiPostfix,{
        ...session,
        imgdata:imgdatauri
    });
    session.beforepic = result.beforepic;
    session.afterpic = result.afterpic;
    return result;
}

export async function setImageSet(beforepath,afterpath){
    const { data:result } = await api.post('update'+apiPostfix, {
        sessionid:session.sessionid,
        beforepic:beforepath,
        afterpic:afterpath
    });
    session.beforepic = beforepath;
    session.afterpic = afterpath;
    console.log(result);
    return result;
}

export async function setProfile(profile){
    const { data:result } = await api.post('update'+apiPostfix, {
        sessionid:session.sessionid,
        firstname:profile.firstname,
        lastname:profile.lastname,
        email:profile.email,
        waitlist:profile.waitlist
    });
    console.log(result);
    return result;
}
export function getAfterImage(){
    return session.afterpic;
}

export function getSessionID(){
    return session.sessionid;
}
export function resetSession(){
    session = null;
}

function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

export function getSetting(prop){
    return session[prop];
}
export function setSetting(prop,val){
    session[prop] = val;
}

// todo. error handling.