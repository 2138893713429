import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import { Splash, Welcome, Camera, Preview, Signup, FilterError, Recovery } from './components/pages';
import Question from './components/Question';
import { useLocation } from 'react-router-dom';
import './App.scss';
import { init } from './components/Model';
// import { render } from '@testing-library/react';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const settings = require('./config.json');

class App extends Component {
  constructor(props){
    super(props);

    this.state = {
      logoURL:'',
      inlineStyles:{},
    }
  }

  componentDidMount() {
    const apiPath = BACKEND_URL;
    const apiPostfix = '';
    init(apiPath, apiPostfix);
    
    // Setting the state with settings data
    this.setState({ 
      ...settings 
    });
  
    // load external style sheet
    if (settings.styles && settings.styles.external) {
      if (!document.getElementById('externalStyles')) {
        const csstag = document.createElement('link');
        csstag.rel = "stylesheet";
        csstag.id = 'externalStyles';
        csstag.href = settings.styles.external;
        document.head.appendChild(csstag);
      }
    }
    
    // attach inline styles
    if (settings.styles && settings.styles.inline) {
      this.setState({ inlineStyles: settings.styles.inline });
    }
  }
  

  render() {
    const { logoURL, mobileLogoURL, inlineStyles, welcome, disclaimer, selfie, share, siteid, splash, filterError } = this.state;
  
    // Ensure splash and its logoURL are available
    const splashLogoURL = splash && splash.logoURL ? splash.logoURL : logoURL;  // Fallback to default logo if splash logo is unavailable
  
    return (
      <div className="App" style={ inlineStyles }>
        <Router>
          <Header 
            defaultLogoURL={logoURL}
            splashLogoURL={splashLogoURL}
            mobileLogoURL={mobileLogoURL}
          />
          {siteid && <Routes>
            <Route exact path="/" element={<Splash settings={splash} />} />
            <Route exact path="/welcome" element={<Welcome wsettings={welcome} dsettings={disclaimer} />} />
            <Route exact path="/question1" element={<Question key={1} settings={settings.question1} background={settings.background} foreground={settings.foreground} />} />
            <Route exact path="/takepic" element={<Camera settings={selfie} />} />
            <Route exact path="/review" element={<Preview settings={share} />} />
            <Route exact path="/error" element={<FilterError settings={share.error} />} />
            <Route exact path="/signup/:id" element={<Signup settings={share} />} />
            <Route exact path="/recovery/:id" element={<Recovery settings={share.recovery} />} />
          </Routes>}
        </Router>
      </div>
    );
  }
}  

function Header( { defaultLogoURL, splashLogoURL ,mobileLogoURL} ){
  const location = useLocation();
  const path = location.pathname;


  // Dynamically set the logo URL based on the current route
  let logoToUse;
  if (path === '/' || path === '/welcome') {
    logoToUse = splashLogoURL;  // Logo for splash screen
  } else {
    logoToUse = defaultLogoURL;  // Default logo for other pages
  }
  // const [display, setDisplay] = useState(
  //   path !== '/takepic' ? true : false
  // );
  return (
    <>
      <header className="App-header">
        <img src={logoToUse} className="App-logo" alt="logo" />
        {mobileLogoURL && 
          <img src={mobileLogoURL} className="App-logo-mobile" alt="logo" />
        }
      </header>
    </>
  );
}


export default App;
