import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../scss/Splash.scss';
import { Tween } from 'react-gsap';

export default function Splash({settings}){
    const navigate = useNavigate();
    const [ clicked, setClicked ] = useState(false);

    function handleOnClick(){
        setClicked(true);
        setTimeout( ()=> navigate('/welcome'), 1000 );
    }
    return (
        <div onClick={handleOnClick} className='fullscreen splash'>
            <Tween from={{ scale:0, alpha:0 }} ease="back.out(1.7)">
            <h1>Ready to <span className='orange-text'>see yourself</span><br/>in a whole new way?</h1>
            {/* <h5>{settings.intro}</h5> */}
            <div className='splash-image'></div>
            </Tween>
            <div onClick={handleOnClick} className='button control-cta light'>
                <p>{settings.cta}</p>
                <div className='arrow'></div>
            </div>
        </div>
    );
}